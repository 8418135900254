<template>
  <div class="container">
    <el-row :gutter="20">
      <el-col :span="12">
        <div class="label">
          订单号
        </div>
        <div class="value">
          {{orderData.ordercode ? orderData.ordercode :  '无'}}
        </div>
      </el-col>
      <el-col :span="12">
        <div class="label">
          医院名
        </div>
        <div class="value">
          {{orderData.categoryfullname ? orderData.categoryfullname :  '无'}}
        </div>
      </el-col>
    </el-row>
    <el-row :gutter="20">
      <el-col :span="12">
        <div class="label">
          订单金额
        </div>
        <div class="value">
          {{orderData.orderamount ? orderData.orderamount :  '无'}}
        </div>
      </el-col>
      <el-col :span="12">
        <div class="label">
          订单状态
        </div>
        <div class="value">
          <span v-if="orderData.orderstatus == 0">待支付</span>
          <span v-if="orderData.orderstatus == 5">支付成功</span>
          <span v-if="orderData.orderstatus == 2">支付失败</span>
          <span v-if="orderData.orderstatus == 6">已使用</span>
        </div>
      </el-col>
    </el-row>
    <el-row :gutter="20">
      <el-col :span="12">
        <div class="label">
          用户编码
        </div>
        <div class="value">
          {{orderData.cusercode ? orderData.cusercode :  '无'}}
        </div>
      </el-col>
      <el-col :span="12">
        <div class="label">
          下单时间
        </div>
        <div class="value">
          {{orderData.createtime ? orderData.createtime :  '无'}}
        </div>
      </el-col>
    </el-row>
  </div>
</template>

<script>
export default {
  name: "OrderHistoryDetails",
  props:{
    orderData: Object,
  }
}
</script>

<style scoped>
.container{

}
.label{
  line-height: 30px;
  color: #303133;
}
.value{
  line-height: 30px;
  color: #909399;
}
</style>