<template>
	<div class="container">
    <div style="height: 20px"></div>
    <div style="padding: 20px; display: flex; align-items: center">
      <span style="font-size: 14px; color: #606266">搜索医院：</span>
      <el-input placeholder="请输入医院名称" v-model="hospitalname" clearable="true" style="width: 280px" :disabled="isHospitalAccount">
        <el-button slot="append" icon="el-icon-search" @click="refreshList" :disabled="isHospitalAccount"></el-button>
      </el-input>
      <span style="margin-left: 20px; font-size: 14px; color: #606266;">搜索指导医生：</span>
      <el-input placeholder="请输入指导医生" v-model="zdys" clearable="true" style="width: 280px">
        <el-button slot="append" icon="el-icon-search" @click="refreshList"></el-button>
      </el-input>
      <span style="margin-left: 20px; font-size: 14px; color: #606266;">选择区域：</span>
      <el-cascader
          :disabled="isHospitalAccount"
          style="width: 280px"
          v-model="areaModel"
          :options="areaList"
          :props="{ checkStrictly: true }"
          @change="onAreaChange"
          clearable>
      </el-cascader>
    </div>
    <div style="padding: 20px; display: flex; align-items: center">
      <span style="font-size: 14px; color: #606266">时间范围：</span>
      <el-date-picker
          v-model="dateRange"
          type="daterange"
          unlink-panels
          range-separator="至"
          start-placeholder="开始日期"
          end-placeholder="结束日期"
          :picker-options="pickerOptions"
          value-format="yyyy-MM-dd HH:mm:ss"
          @change="onPickerDateChange">
      </el-date-picker>
    </div>

		<div style="padding: 20px">
			<span style="font-size: 14px; color: #606266">订单状态：</span>
			<el-radio-group v-model="orderStatus" size="medium" @change="onOrderStatusChange">
				<el-radio-button label="全部"></el-radio-button>
				<el-radio-button label="支付成功"></el-radio-button>
				<el-radio-button label="支付失败"></el-radio-button>
				<el-radio-button label="已使用"></el-radio-button>
			</el-radio-group>
		</div>
		<el-table :data="tableData" v-loading="loading" border size="small" style="width: 100%">
			<el-table-column type="index" label="#" width="50"> </el-table-column>
			<el-table-column prop="ordercode" label="订单号" width="180">
			</el-table-column>
			<el-table-column prop="hospitalname" label="医院名" width="250">
			</el-table-column>
			<el-table-column label="订单状态" width="160">
				<template slot-scope="scope">
					<span v-if="scope.row.orderstatus == 0">待支付</span>
					<span v-if="scope.row.orderstatus == 5">支付成功</span>
					<span v-if="scope.row.orderstatus == 2">支付失败</span>
					<span v-if="scope.row.orderstatus == 6">已使用</span>
          <span v-if="scope.row.orderstatus == 9">退款成功</span>
				</template>
			</el-table-column>
			<el-table-column prop="orderamount" label="订单金额(￥)" width="160">
			</el-table-column>
			<el-table-column prop="createtime" label="下单时间" min-width="160">
			</el-table-column>
			<el-table-column fixed="right" label="操作" width="220">
				<template slot-scope="scope">
					<el-button @click="viewDetails(scope.row)" plain size="small">查看详情</el-button>
					<el-button @click="returnMoney(scope.row)" plain size="small" type="primary" :loading="loading">退款
					</el-button>
				</template>
			</el-table-column>
		</el-table>
		<div class="footer">
			<el-pagination background @current-change="handleCurrentChange" @size-change="handleSizeChange"
				:page-sizes="[10, 15, 30]" :page-size="pageSize" layout="total,sizes, prev, pager, next, jumper"
				:total="totalData">
			</el-pagination>
		</div>
		<el-dialog append-to-body title="订单详情" :visible.sync="viewDetailDialog" width="700px">
			<OrderHistoryDetails :order-data="viewDetailObj"></OrderHistoryDetails>
		</el-dialog>
	</div>
</template>

<script>
	import api from "../../api";
	import OrderHistoryDetails from "../../components/OrderHistoryDetails";
  import axios from "axios";

	export default {
		components: {
			OrderHistoryDetails
		},
		data() {
			return {
				pageIndex: 1,
				pageSize: 15,
				totalData: 0,
				tableData: [],
				viewDetailDialog: false,
				viewDetailObj: null,
				orderStatus: "全部",
				categoryfullname: "",
				ordercode: "",
				loading: false,
        areaList: [],
        areaModel: ["","",""],
        dateRange: ["",""],
        pickerOptions: {
          shortcuts: [{
            text: '今天',
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setHours(0);
              start.setMinutes(0);
              start.setSeconds(0);
              end.setHours(23);
              end.setMinutes(59);
              end.setSeconds(59);
              picker.$emit('pick', [start, end]);
            }
          },{
            text: '昨天',
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24);
              picker.$emit('pick', [start, end]);
            }
          },{
            text: '本周',
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
              picker.$emit('pick', [start, end]);
            }
          },{
            text: '本月',
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
              picker.$emit('pick', [start, end]);
            }
          }, {
            text: '半年',
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 180);
              picker.$emit('pick', [start, end]);
            }
          }, {
            text: '本年',
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 365);
              picker.$emit('pick', [start, end]);
            }
          }]
        },
        hospitalname: "",
        zdys: "",
        isHospitalAccount: false,
			};
		},
		created() {
			this.loginData = JSON.parse(localStorage.getItem("logindata"));
      this.isHospitalAccount = localStorage.getItem("ishospital") == 1;
			this.initList();
      this.initAreaList();
		},
		methods: {
      refreshList(){
        this.initList();
      },
			initList() {
				this.loading = true;
				let status = "";
				switch (this.orderStatus) {
					case "全部":
						status = "";
						break;
					case "支付成功":
						status = "5";
						break;
					case "支付失败":
						status = "2";
						break;
					case "已使用":
						status = "6";
						break;
				}
				let params = {
          ordercode: this.ordercode,
          orderstatus: status,
          categorycode: this.loginData.categorycode,
          userlevel: this.loginData.userlevel,
          page: this.pageIndex,
          pageSize: this.pageSize,
          zdys: this.zdys,
          categoryfullname: this.hospitalname,
        };
        if (this.dateRange) {
          params.starttime = this.dateRange[0];
          params.endtime = this.dateRange[1];
        }
        if (this.areaModel) {
          params.provinceid = this.areaModel[0];
          params.cityid = this.areaModel[1];
          params.districtid = this.areaModel[2];
        }
				api
					.getMUserOrderPageList(params)
					.then((res) => {
						if (res.data.success === "1") {
							this.totalData = res.data.data.totalRecords;
							this.tableData = res.data.data.list;
						} else {
							this.$message({
								showClose: true,
								message: res.data.errorMsg,
								type: "warning",
							});
						}
            this.loading = false;
					})
					.catch(() => {
						this.loading = false;
						this.$message({
							showClose: true,
							message: "请检查网络连接",
							type: "warning",
						});
					});
			},
      initAreaList() {
        let url = "https://restapi.amap.com/v3/config/district?key=4f3b15d4e9ec39a20d3fb459c65ecf17&subdistrict=3";
        this.loading = true;
        axios.get(url).then((res) => {
          console.log(res);
          let list = [];
          for (let pIndex in res.data.districts[0].districts) {
            let pItem = res.data.districts[0].districts[pIndex];
            let value = {};
            value.provinceid = pItem.adcode;
            value.label = pItem.name;
            value.value = pItem.adcode;
            if (pItem.districts && pItem.districts.length > 0) {
              value.children = [];
            }
            for (let cIndex in pItem.districts) {
              let cItem = pItem.districts[cIndex];
              let cValue = {
                cityid: cItem.adcode,
                label: cItem.name,
                value: cItem.adcode,
              };
              if (cItem.districts && cItem.districts.length > 0) {
                cValue.children = [];
              }
              for (let dIndex in cItem.districts) {
                let dItem = cItem.districts[dIndex];
                cValue.children.push({
                  districtid: dItem.adcode,
                  label: dItem.name,
                  value: dItem.adcode,
                });
              }
              value.children.push(cValue);
            }
            list.push(value);
          }
          this.areaList = list;
          this.loading = false;
        });
      },
      onPickerDateChange(e){
        console.log(e);
        this.dateRange = e;
        this.refreshList();
      },
      onAreaChange(value) {
        console.log(value);
        this.areaModel = value;
        this.refreshList();
      },
			viewDetails(row) {
				this.viewDetailObj = row;
				this.viewDetailDialog = true;
				console.log(this.viewDetailObj);
			},
			handleCurrentChange(val) {
				this.pageIndex = val;
				this.initList();
			},
			handleSizeChange(val) {
				this.pageSize = val;
				this.initList();
			},
			onOrderStatusChange() {
				this.initList();
			},
			searchList() {
				this.pageIndex = 1;
				this.initList();
			},
			clearHospitalSearchValue(){
				this.categoryfullname="";
				this.initList();
			},
			clearOrderSearchValue(){
				this.ordercode="";
				this.initList();
			},
			returnMoney(row) {
				this.loading = true;
				console.log(row);
				this.$prompt("请输入密码", "提示", {
						confirmButtonText: "确定",
						cancelButtonText: "取消",
						inputType: "password",
					})
					.then(({
						value
					}) => {
						this.$https.reportsService
							.checkAccountPwd({
								password: this.$md5(value),
								account: localStorage.getItem("account"),
							})
							.then((res) => {
								console.log(res);
								if (res.success == "false") {
									this.$message({
										type: "error",
										message: "密码错误",
									});
									this.loading = false;
								} else {
									this.$https.reportsService
										.merrefund({
											ordercode: row.ordercode,
											refundamount: Number(row.orderamount) * 100,
											createuser: localStorage.getItem("usercode"),
											comments: "",
										})
										.then((data) => {
											console.log(data);
											if (data.issuccess == "true") {
												this.$message({
													type: "success",
													message: "退款成功",
												});
												this.loading = false;
											} else {
												this.$message({
													type: "error",
													message: "退款失败",
												});
												this.loading = false;
											}
										});
								}
							});
					})
					.catch(() => {
						this.$message({
							type: "info",
							message: "取消输入",
						});
						this.loading = false;
					});
			},
		},
	};
</script>

<style scoped>
	.container {
		display: flex;
		flex-direction: column;
		padding: 5px;
	}

	.footer {
		display: flex;
		height: 100px;
		justify-content: center;
		align-items: center;
	}
</style>
